<style scoped>
	.content {
		width: 1320px;
		margin: 0 auto;
		padding: 65px 20px;
	}
	.con-p1 {
		font-size: 32px;
		color: #252B3A;
		text-align: center;
	}
	.con-p2 {
		color: #666A75;
		font-size: 15px;
		margin: 40px 0 30px;
	}
	.con-p3 {
		color: #75B9F6;
		font-size: 13px;
		text-align: center;
	}
	.content img {
		width: 949px;
		height: 768px;
	}
	.con-img {
		width: 100%;
		height: 800px;
		margin-top: 120px;
	}
</style>

<template>
	<div>
		<Top :topTitle="topTitle" :topTitle2="topTitle2"></Top>
		<div class="content">
			<p class="con-p1">项目简介</p>
			<div class="con-p2" v-html="info.content"></div>
			<p class="con-p3" v-if="info.url">访问地址：{{info.url}}</p>
			<img :src="item" alt="" v-for="(item, index) in info.info_image" :key="index">
		</div>
		<Bottom :minWidth="minWidth"></Bottom>
	</div>
</template>

<script>
	import Top from "../../components/top.vue";
	import Bottom from "../../components/bottom.vue";
	export default {
		data() {
			return {
				info:{
					title: "",
					image: "",
					introduction: "",
					content: "",
					web_title: "",
					web_keywords: "",
					web_description: "",
					store_name: "",
					store_logo: "",
				},
				topTitle: "深圳腾讯科技有限公司",
				topTitle2: "生态校园小程序",
				minWidth: 1320,
			}
		},
		created(){
			this.getInfo(this.$route.query.id)
		},
		methods:{
			getInfo(id){
				this.http({
					method: "get",
					url: this.api.getProductInfo,
					data: { id },
				}).then(res => {
					this.topTitle = res.data.store_name;
					this.topTitle2 = res.data.title;
					this.info = res.data;
					document.title = res.data.web_title ? "佑护科技 " + res.data.web_title : "佑护科技 合作客户 " + res.data.title;
					if(res.data.web_keywords){
						document.querySelector('meta[name="keywords"]').setAttribute("content", res.data.web_keywords);
					}
					if(res.data.web_description){
						document.querySelector('meta[name="description"]').setAttribute("content", res.data.web_description);
					}
				})
			},
		},
		components: {
			Top, Bottom
		}
	}
</script>


